<template>
    <div class="print-page-break">
        <!-- optional section switch button -->
        <v-container  v-if="section.optional" fluid class="print-block">
            <v-switch
                color="primary"
                v-model="skipSection"
                :label="switchButtonLabel(section)"
                @change="emitSkipSectionChange"
            />
        </v-container>

        <v-container>
            <v-row class="position-sticky white print-block print-margin-top">
                <v-col cols="12" class="print-space">
                    <h2 class="headline print-text-center">
                        Section {{ sectionIndex + 1 }} / {{ sectionsTotal }} : {{ setLanguage(section.title, selectedLang) }}
                    </h2>
                </v-col>
            </v-row>
            <v-row class="print-block">
                <v-col cols="12" class="print-space">
                    <div v-html="setLanguage(section.description, selectedLang)" class="section-description print-text-center"></div>
                </v-col>
            </v-row>
            <v-row v-for="(question, questionIndex) in section.questions" :key="question.id" class="ma-0 pa-0 print-block">
                <v-col cols="12" class="ma-0 pa-0">
                    <!-- the span element creates a headbutt point so when clicking on the link it wont to scroll too far anymore-->
                    <span class="headbutt" :id="!!question && !!question.id ? question.id.toString() : ''"/>

                    <QuestionAnswer
                        ref="questionAnswers"
                        :id="question.id"
                        class="print-page-break print-space"
                        :object="question"
                        :answer-object="getQuestionAnswer(question.id)"
                        :order="`${sectionIndex + 1}.${questionIndex + 1}`"
                        :questionIndex="questionIndex"
                        :selectedLang="selectedLang"
                        :isSubmit="isSubmit"
                        :assignationIdToOutput="assignationIdToOutput"
                        :checkboxActivatorIndentation="0"
                        @answerChange="emitAnswerChange"
                        @imagesChange="emitImagesChange"
                        :readonly="readonly"
                        :print="print"
                        :section-is-optional="skipSection"
                        :parent-trail="setLanguage(section.title, selectedLang)"
                    />
                </v-col>
            </v-row>

            <!--buttons change section right, change section left and submit-->
            <v-row v-if="showArrows" class="print-block">
                <v-col cols="6" class="my-0 py-0 text-right">
                    <v-btn fab color="primary" @click="emitLeftArrowClick"><v-icon>arrow_back</v-icon></v-btn>
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                    <v-btn fab color="primary" @click="emitRightArrowClick" v-if="!isLastSection"><v-icon>arrow_forward</v-icon></v-btn>
                    <v-btn fab color="success" @click="emitSubmit" v-else><v-icon>cloud_upload</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import QuestionAnswer from '../Answers/QuestionAnswer';
    import shared from '../../shared';

    export default {
        name: "SurveySection.vue",
        props: [
            'section',
            'sectionIndex',
            'selectedLang',
            'getQuestionAnswer',
            'assignationIdToOutput',
            'isSubmit',
            'showArrows',
            'isLastSection',
            'readonly',
            'sectionsTotal',
            'print',
            'skipped'
        ],
        components: { QuestionAnswer },
        updated() {
            let triggerOn = false;
            this.section.questions.forEach((question, index)=>{
                let answer = this.getQuestionAnswer(question.id);

                if (!answer.isComplete && question.question.optionOptional === null && !triggerOn) {

                    triggerOn = true;

                    if(question.children.length > 0){
                        console.log("index = " + index);
                        console.log('Target on => Multiple LEVEL 1, nb of child => '+ question.children.length);

                        question.children.forEach((child, index)=>{
                            index++;
                            if(child.children.length > 0){
                                console.log('   ('+index+')Target on => Multiple LEVEL 2, nb of child => '+ child.children.length);

                                child.children.forEach((childLevel2, index2)=>{
                                    index2++;
                                    if(childLevel2.children.length > 0){
                                        console.log('       ('+index2+')Target on => Multiple LEVEL 3, nb of child => '+ childLevel2.children.length);
                                    }
                                    else{
                                        console.log('       ('+index2+')Target on => Simple LEVEL 3');
                                        let childAnswer = this.getQuestionAnswer(childLevel2.id);
                                        //console.log(childLevel2);
                                        console.log(childAnswer);
                                    }

                                });
                            }
                            else{
                                console.log('   ('+index+')Target on => Simple LEVEL 2');
                            }
                        });
                    }
                    else{
                        console.log('Target on => Simple LEVEL 1');
                    }
                }
            });

            this.$nextTick(() => {
                this.getNextQuestionOnFocus(this.section);
            });
        },
        mounted() {
            this.setLanguage = shared.setLanguage;
            this.skipSection = this.skipped;
        },
        data: function() {
            return {
                //shared functions from shared.js
                setLanguage: function() {},
                skipSection: false,
                currentElement: null
            }
        },
        methods: {
            //switch button  Optional section label text
            switchButtonLabel(section) {
                return section.optional ? this.setLanguage(section.optionalLabel, this.selectedLang) : '';
            },

            emitAnswerChange(event) {
                this.$emit('answerChange', event);
            },

            emitImagesChange(event) {
                this.$emit('imagesChange', event);
            },

            emitSubmit() {
                this.$emit('submit');
            },

            emitRightArrowClick() {
                this.$emit('rightArrowClick');
            },

            emitLeftArrowClick() {
                this.$emit('leftArrowClick');
            },
            emitSkipSectionChange() {
                this.$emit('skipSectionChange', this.skipSection);
            },

            //Question on focus (11/10/2024) *KB*
            getNextQuestionOnFocus(section) {
                for (let i = 0; i < section.questions.length; i++) {
                    let question = section.questions[i];
                    let answer = this.getQuestionAnswer(question.id);
                    let element = this.$refs.questionAnswers[i].$refs[question.id];

                    if (!answer.isComplete && element && question.question.optionOptional === null) {
                        if (question.children && question.children.length > 0) {
                            element = this.targetChildrenQuestionOnFocus(question.children, this.$refs.questionAnswers[i], "onStart");
                        }

                        this.$nextTick(()=>{
                            this.elementStyleFocus(element);
                            element.scrollIntoView({behavior: "smooth", block: "start", inline: 'start'});
                        });
                        break;
                    }
                }
            },
            targetChildrenQuestionOnFocus(children, parentElementRefs, onState) {
                for (let i = 0; i < children.length; i++) {
                    let str = children[i].id + "-child-component";

                    if (children[i].children.length > 0) {
                        if(onState === "onRecall"){
                            parentElementRefs  = parentElementRefs.$refs[str];
                        }
                        return this.targetChildrenQuestionOnFocus(children[i].children, parentElementRefs, "onRecall");
                    }
                    else if(children[i].children.length === 0){
                        for (let y = 0; y < children.length; y++) {
                            let childAnswer = this.getQuestionAnswer(children[y].id);
                            let childElementRefs = null;

                            if(children[y].question.optionYesNoImages){
                                if(children[y].question.optionYesNoImages.imageCount !== childAnswer.imagesFolder.length){
                                    childAnswer.isComplete = false;
                                }
                            }

                            if(!childAnswer.isComplete && children[y].question.optionOptional === null){
                                if(onState === "onStart"){
                                    str = children[y].id + "-child-component";
                                    childElementRefs = parentElementRefs.$refs[str];
                                    childElementRefs = childElementRefs[0].$refs[children[y].id];
                                }
                                else {
                                    str = children[y].parentId + "-child-component";
                                    childElementRefs = parentElementRefs.$refs[str];
                                    str = children[y].id + "-child-component";
                                    childElementRefs = childElementRefs[0].$refs[str];
                                    childElementRefs = childElementRefs[0].$refs[children[y].id];
                                }

                                return childElementRefs;
                            }
                        }
                    }
                }
            },
            elementStyleFocus(element){
                if(element.classList.contains('grey')) {
                    element.classList.remove('grey');
                    element.classList.add('on-focus-question-grey');
                }
                else{
                    element.classList.add('on-focus-question');
                }
            },

            checkMultipleQuestion(question){
                //console.log(question);
                // if(question.children.length > 0){
                //     question.children.forEach((child)=>{
                //         if(child.children.length > 0){
                //             return this.checkMultipleQuestion(child);
                //         }
                //         else{
                //             return child;
                //         }
                //     });
                // }
                // else{
                //     return question;
                // }
            }
        },
        watch: {
            skipped(newValue) {
                this.skipSection = newValue;
            }
        }
    }
</script>

<style scoped>
    div.section-description p img{
        max-width: 350px;
    }

    .headbutt {
        display: block;
        height: 150px; /*same height as header*/
        margin-top: -150px; /*same height as header*/
        visibility: hidden;
    }

    .position-sticky {
        position: sticky;
        top: 1px;
        left: 0;
        z-index: 1;
        box-shadow: 0 1px 5px #000;
    }

    @media print {
        .print-page-break {
            break-inside: avoid;
        }

        .print-text-center {
            text-align: center;
        }

        .print-space {
            margin: 0;
            padding: 0;
        }

        .print-block {
            display:block;
        }

        .print-margin-top {
            margin-top: 15px;
        }
    }
    @media (max-width: 600px) {
        ::v-deep .section-description img{
            max-width: 350px;
        }
    }
</style>
