<i18n>
{
    "en": {
        "love": "Made with love at Stageline",
        "hello": "Hello, "
    },
    "fr": {
        "love": "Fait avec amour chez Stageline",
        "hello": "Bonjour, "
    }
}
</i18n>

<template>
    <v-app>
        <v-app-bar
            app
            color="black"
            dark
            clipped-left
            class="d-print-none"
            v-if="!mobileDisplay && showMenuBar"
        >
            <!-- QUESTIONS VIEW, CATEGORIES VIEW, TAGS VIEW -->
            <v-menu offset-y v-if="loggedUser.shopUser !== 0">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" light class="mx-3 gl-btn" >
                        Questions
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/index-question">
                        <v-list-item-title>Index des questions</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/index-categories" v-if="loggedUser.createTagsCategories">
                        <v-list-item-title>Categories de questions</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/index-tags" v-if="loggedUser.createTagsCategories">
                        <v-list-item-title>Tags de questions</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- SURVEYS VIEW, SURVEY TYPES VIEW -->
            <v-menu offset-y v-if="loggedUser.shopUser !== 0">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" light class="mx-3 gl-btn">
                        Questionnaires
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/index-survey">
                        <v-list-item-title>Index des questionnaires</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/index-survey-types" v-if="loggedUser.createSurveys">
                        <v-list-item-title>Types de questionnaires</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- ASSIGNATION VIEW -->
            <v-menu offset-y v-if="loggedUser.shopUser !== 0">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" light class="mx-3 gl-btn">
                        Assignations
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/index-assignations">
                        <v-list-item-title>Index des assignations</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- MAINTENANCE VIEW -->
            <v-menu offset-y v-if="loggedUser.shopUser !== 0">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" light class="mx-3 gl-btn">
                        Maintenance
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/index-maintenance">
                        <v-list-item-title>Tableau de bord maintenance</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/maintenance-stages">
                        <v-list-item-title>Scènes</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/index-nc-reports">
                        <v-list-item-title>Index des rapports</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- ADMIN VIEW -->
            <v-menu offset-y v-if="loggedUser.admin">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" light class="mx-3 gl-btn">
                        Administration
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item to="/user-management">
                        <v-list-item-title>Gestion des utilisateurs</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- Retour index des rapports pour shop user seulement -->
            <v-btn class="gl-btn" v-if="loggedUser.shopUser === 0 && isOnSectionNcRepport" to="/index-nc-reports">
                <span>
                    <v-icon>arrow_circle_left</v-icon>
                </span>
                <span class="pl-1 gl-fs-16">Retoux index des rapports</span>
            </v-btn>

            <v-spacer></v-spacer>

            <span class="pr-2">
                {{ $t('hello') }}{{ loggedUser.fullName }}
            </span>

            <v-icon class="gl-fs-30" v-if="isOnSectionIndexNcRepport || isOnSectionNcRepport" @click="toggleLegend">info_outline</v-icon>

            <!-- logout button -->
            <v-btn class="gl-btn ml-3" @click="logout">Quitter</v-btn>
        </v-app-bar>

        <!-- DEVELOPMENT MODE  -->
        <DevBuilder
            v-if="ENV.DEV_MODE"
            :devMessages="devMessages"
        />

        <!-- ROUTES CONTENT -->
        <v-main class="gl-bg-color-main">
            <v-btn
                absolute
                top
                right
                fab
                x-small
                class="mt-6"
                @click="toggleLocale"
            >{{ currentLocale }}</v-btn>
            <router-view/>
        </v-main>

        <!-- FOOTER -->
        <v-footer
            app
            color="black"
            class="white--text print-none"
            v-if="!mobileDisplay"
        >
            <span class="gl-fs-18">{{ $t('love') }}</span>
            <v-spacer/>
            <span class="gl-main-title">Gallup</span>
            <v-spacer/>
            <span class="gl-fs-18">&copy; 1987 - {{ currentYear }}</span>
        </v-footer>

        <!-- OVERLAY ERROR -->
        <v-overlay :value="isLoading" z-index="10000">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="12" class="position-relative">
                    <v-img :src="logoStageline" class="h-screen w-100 position-relative" style="width: 130px;box-shadow: 0px 0px 5px rgba(0, 128, 128, 0.74);"></v-img>
                    <v-progress-circular
                        v-if="!isErrorState"
                        class="position-absolute circle-shadow"
                        color="teal"
                        indeterminate
                        :size="250"
                        :width="5"
                    />
                </v-col>
            </v-row>
            <v-row align="center" v-if="isLoading && !isErrorState" class="mx-0 px-0">
                <v-col cols="12" class="display-1">
                    {{ loadingMessage }}
                </v-col>
            </v-row>
            <v-row align="center" v-if="isErrorState">
                <v-col cols="12">
                    <v-icon size="64" color="red">error</v-icon>
                    {{ errorMessage }}
                </v-col>
            </v-row>
            <v-row style="text-align: center" v-if="isLoading && (isErrorState || showLoadingButton)" >
                <v-col cols="12">
                    <v-btn @click="closeError">Fermer</v-btn>
                </v-col>
            </v-row>
        </v-overlay>

        <!-- Legend color of the Chart -->
        <v-card v-if="showLegendDialog" style="position: fixed; top:60px; right:150px;">
            <v-card-title class="pb-1 gl-fs-20 gl-ft-bolder">
                Légende états des FUN's
            </v-card-title>
            <v-card-text class="pt-1">
                <v-row>
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#008062;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Nouveau</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#9a9a9a;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">À Reviser</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#814500;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Révisé par ING</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#e30cdf;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Planifié</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#0051ff;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">En cours</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#eaca0a;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">À valider</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#ffc080;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Tâche complété</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#0aeac9;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Terminé</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#a50df3;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Fermé</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#090909;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Supprimé</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#51df3d;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Conforme</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#ff4545;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">Non-Conforme</div>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col cols="3" class="pr-0">
                        <div class="gl-dot-legend" style="background-color:#ff6700;"></div>
                    </v-col>
                    <v-col cols="9">
                        <div class="gl-fs-18 gl-ft-bolder gl-txt-color-2">À Refaire</div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import DevBuilder from "@/components/Dev/DevBuilder.vue";
import {ENV} from "@/constants";
import logoStageline from '@/assets/img/logo-stageline.png';

export default {
    name: 'App',
    components: {DevBuilder},
    props: {
        source: String,
    },
    async mounted() {
        window.addEventListener('beforeunload', this.logoutUser);
        await this.fetchUser();
        this.initLocale();//Error *KB*
        this.isUserAllreadyLoggedIn();
    },
    computed: {
        ENV() {
            return ENV
        },
        //VUEX
        ...mapState(['isLoading', 'showLoadingMessage', 'loadingMessage', 'showLoadingButton', 'isErrorState', 'errorMessage', 'loggedUser', 'availableLocales', 'devMessages']),

            //mobile display
            mobileDisplay() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return true;
                    case 'sm': return true;
                    case 'md': return true;
                    case 'lg': return false;
                    case 'xl': return false;
                }
            },

            //shows menu bar
            showMenuBar() {
                return Object.keys(this.loggedUser).length >= 1;
            },

            currentLocale() {
                return this.$i18n.locale;
            },

            currentYear() {
                return new Date().getFullYear();
            },

            onRoutePath(){
                return this.$route.path;
            }
    },
    data: () => ({
        urlName:window.location.pathname,
        drawer: null,
        drawerRight: null,
        right: false,
        isOnSectionIndexNcRepport:false,
        isOnSectionNcRepport:false,
        showLegendDialog:false,
        logoStageline:logoStageline,
    }),
    methods: {
        ...mapActions(['fetchUser']),
        ...mapMutations(['setIsLoading', 'setIsErrorState', 'setErrorMessage', 'setShowLoadingMessage', 'setLoadingMessage', 'setLocale', 'deleteTokenInfo', 'setLocale', 'initLocale']),

        //closes error display
        closeError() {
            this.setErrorMessage('');
            this.setIsErrorState(false);
            this.setIsLoading(false);
        },

        //logout
        logout() {
            //deletes JWT
            this.deleteTokenInfo();

            //redirect to login page (we use window.location so it will reload the page and so the loggedUser object at the same time)
            window.location = '/';
        },
        logoutUser() {
            if(this.loggedUser.shopUser === 0){
                this.logout();
            }
        },

        toggleLocale() {
            this.setLocale(this.availableLocales.filter(l => l !== this.$i18n.locale)[0]);
        },

        isUserAllreadyLoggedIn(){
            //TODO : Logique afin de limiter l'accès aux pages et aux points morts.
            if((this.urlName === '/en/login' || this.urlName === '/fr/login') && this.loggedUser !== null){
                window.location = '/';
            }
        },

        toggleLegend(){
            this.showLegendDialog = !this.showLegendDialog;
        },
    },
    watch: {
        onRoutePath(routePath) {
            this.isOnSectionNcRepport = !!routePath.includes('/nc-report/');
            this.isOnSectionIndexNcRepport = !!routePath.includes('/index-nc-reports');
            if(!this.isOnSectionIndexNcRepport && !this.isOnSectionNcRepport){
                this.showLegendDialog = false;
            }
        }
    }
}
</script>

<style>
    * {
        font-family: 'Open Sans', sans-serif;
    }

    .gl-main-title{
        font-family: 'Sofia';
        font-size: 18px;
        font-weight: bolder;
    }

    .v-btn:not(.v-btn--round).v-size--default {
        font-size: 16px;
    }

    .gl-btn {
        font-weight: bolder;
    }

    .gl-btn:hover {
        top: -0.16em;
        transition: padding .5s, top .35s, box-shadow .4s;
        box-shadow: 2px 2px 12px #1D709B;
    }

    ::v-deep .custom-dialog-class {
        background-color: red !important;
    }

    .position-relative {
        position: relative;
    }

    .position-absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circle-shadow {
        filter: blur(2px);
    }

    /* print stylesheet */
    @media print {
        .print-none {
            display: none;
        }
    }
</style>
